// Libs.
import React from 'react';
// Deps.
import Layout from '../components/layout/layout';
import SEO from '../components/layout/seo';
import FoodieLanding from '../components/foodie-finds/foodie-landing';
import { graphql } from 'gatsby';
import { formatFieldsValue } from '../libs/formatFromGraph';

const FoodieFinds = ({ data }) => {
  const pageData = formatFieldsValue(data);

  return (
    <Layout>
      <SEO title="Foodie Finds" />
      <FoodieLanding data={pageData} />
    </Layout>
  );
};

export default FoodieFinds;

export const query = graphql`
  query {
    hero: configPagesFoodieFinds {
      id
      label
      nid: drupal_id
      relationships {
        image: field_hero_image {
          localFile {
            childImageSharp {
              fluid(maxHeight: 800, srcSetBreakpoints: [580, 800]) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
      title: field_hero_title {
        value
      }
      body: field_hero_description {
        value
      }
    }
    foodieProducts: configPagesFoodieFinds {
      relationships {
        products: field_product_reference {
          title
          description: body {
            value
          }
          price: field_product_price {
            value
          }
          summary: field_product_quality_descriptio {
            value
          }
          bonus: field_product_savings {
            value
          }
          points: field_points_awards
          relationships {
            image: field_product_images {
              localFile {
                childImageSharp {
                  fluid(maxHeight: 330, srcSetBreakpoints: [330, 330]) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
      }
    }
    flyer: configPagesFoodieFinds {
      relationships {
        flyer: field_foodie_magazine {
          current_issue: field_current_issue
          title: field_banner_title
          label: field_magazine_featured_title
          text: field_magazine_summary {
            value
          }
          link: field_magazine_link {
            title
            uri
          }
          relationships {
            image: field_magazine_banner_image {
              localFile {
                childImageSharp {
                  fluid(maxWidth: 741) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
