// Libs.
import React from 'react';
// Assets.
import styles from './foodie-landing.module.scss';
import flyer from '../../data/featured-in-experience.json';
import Products from '../common/products/products';
import FlyerFullsize from '../common/flyer-fullsize/flyer-fullsize';
import ImageHero from "../common/image-hero/image-hero";

const FoodieLanding = ({ data }) => {

  return (
    <div className={styles.foodieLanding}>
      <ImageHero data={data.hero} />
      <Products data={data.foodieProducts} />
      <FlyerFullsize content={data.flyer.flyer} />
       </div>
  );
};

export default FoodieLanding;
